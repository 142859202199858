import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/resource',
    name: 'resource',
    component: () => import('../views/ResourceView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/ArticleView.vue')
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('../views/IntroView.vue')
  },
  {
    path: '/design',
    name: 'design',
    component: () => import('../views/DesignView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
  {
    path: '/aria',
    name: 'aria',
    component: () => import('../views/AriaView.vue')
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('../views/FormView.vue')
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/PdfView.vue')
  },
  {
    path: '/source',
    name: 'source',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SourceView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/modal',
    name: 'modal',
    component: () => import('../views/ModalView.vue')
  },
  {
  path: '/checklist',
  name: 'checklist',
  component: () => import('../views/ChecklistView.vue')
 },
   {
  path: '/blog',
  name: 'blog',
  component: () => import('../views/BlogView.vue')
 },
 {
  path: '/post1',
  name: 'post1',
  component: () => import('../views/Post1View.vue')
 },
 {
  path: '/keystrokes',
  name: 'keystrokes',
  component: () => import('../views/KeyView.vue')
 },
 {
  path: '/jaws',
  name: 'jaws',
  component: () => import('../views/JawsView.vue')
 },
 {
  path: '/nvda',
  name: 'nvda',
  component: () => import('../views/NvdaView.vue')
 },
 {
  path: '/voiceover',
  name: 'voiceover',
  component: () => import('../views/VoiceView.vue')
 },
   {
  path: '/sect',
  name: 'sect',
  component: () => import('../views/SectView.vue')
 },
   {
  path: '/eaa',
  name: 'eaa',
  component: () => import('../views/EaaView.vue')
 },
 {
    path: '/author',
    name: 'author',
    component: () => import('../views/AuthorView.vue')
  },
   {
    path: '/plan',
    name: 'plan',
    component: () => import('../views/PlanView.vue')
  },
  {
    path: '/aest',
    name: 'aest',
    component: () => import('../views/AestView.vue')
  },
  {
    path: '/git',
    name: 'git',
    component: () => import('../views/GitView.vue')
  },
  {
    path: '/button',
    name: 'button',
    component: () => import('../views/ButtonView.vue')
  },
   {
    path: '/mob',
    name: 'mob',
    component: () => import('../views/MobileView.vue')
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import('../views/AiView.vue')
  },
   {
    path: '/aith',
    name: 'aith',
    component: () => import('../views/AithView.vue')
  },
    {
    path: '/ios',
    name: 'ios',
    component: () => import('../views/IosView.vue')
  },
  {
    path: '/android',
    name: 'android',
    component: () => import('../views/AndroidView.vue')
  },
  {
    path: '/cmob',
    name: 'cmob',
    component: () => import('../views/CmobView.vue')
  },
    {
    path: '/acatt',
    name: 'acatt',
    component: () => import('../views/AcaView.vue')
  },
  {
    path: '/cogdis',
    name: 'cogdis',
    component: () => import('../views/CogdisView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
    scrollBehavior(_, _1, savedPosition){
    if(savedPosition){
        return savedPosition
    }
    return { left: 0, top: 0}
}
})

export default router
